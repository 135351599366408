import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';

import App from './App';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider, Theme, StyledEngineProvider } from '@mui/material/styles';
import { BrowserRouter as Router } from 'react-router-dom';
import './i18n';
import { Provider } from 'react-redux';
import reduxStore from './redux/reduxStore';
import NotistackProvider from './providers/notistackProvider';
import theme from './theme';
import ScrollToTop from './components/ScrollToTop/ScrollToTop';
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import AuthProvider from "./auth/oidc-react/AuthProvider";

import { getNonce } from "./helpers/getNonce";
import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';
import i18n from "./i18n";

declare module '@mui/styles/defaultTheme' {
    // eslint-disable-next-line @typescript-eslint/no-empty-interface
    interface DefaultTheme extends Theme {
    }
}

const queryClient = new QueryClient();

const nonce = getNonce();

// Find the style tag by id
const styleTag = document.getElementById('_goober');

// If the style tag was found, set its nonce attribute
if (styleTag) {
    styleTag.setAttribute('nonce', nonce);
}

const cache = createCache({
    key: 'gvote-key',
    prepend: true,
    nonce: nonce,
});

i18n.on('languageChanged', (lng) => {
  document.documentElement.setAttribute('lang', lng);
});

ReactDOM.render(
    <CacheProvider value={cache}>
        <React.StrictMode>
            <QueryClientProvider client={queryClient}>
                <AuthProvider>
                    <Provider store={reduxStore}>
                        <StyledEngineProvider injectFirst>
                            <ThemeProvider theme={theme}>
                                <CssBaseline />
                                <Router>
                                    <Suspense fallback="">
                                        <ScrollToTop />
                                        <NotistackProvider>
                                                <App />
                                        </NotistackProvider>
                                    </Suspense>
                                </Router>
                            </ThemeProvider>
                        </StyledEngineProvider>
                    </Provider>
                </AuthProvider>
                <ReactQueryDevtools initialIsOpen={false} />
            </QueryClientProvider>
        </React.StrictMode>
    </CacheProvider>,
    document.getElementById('root')
);
